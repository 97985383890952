@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');


.cbtndiv{
  display: flex;
  justify-content: center;
padding-top: 10px;
}
Form{
  background-color:transparent;
}
.cinput{
  height: 30px;
  width: 300px;
  background-color: lightblue;
}
.clab{
  font-family: 'Oswald', sans-serif;
  color: white;
  font-size:18px;
}
.cbtn{
  background-color: blue;
  color: white;
  border-radius: 8px;
}
