#prayercontainer-fluid{
    background-color: black;
    display: grid;
    height: auto;
    text-align: center;
    grid-template-columns: auto auto;
    grid-template-rows: 30px 50px 50px 50px 190px 40px 100px 50px;
    grid-template-areas: "apnav apnav"
                         "aptitle aptitle"
                         "apfirst aplast"
                         "apmail apmail"
                         "apmes apmes"
                         "apbtn apbtn"
                         "apspace apspace"
                         "apfoot apfoot";
    grid-gap: 0.1em;
}
#apnav{
    grid-area: apnav;
    background-color: aqua;
}
#aptitle{
    grid-area: aptitle;
    background-color: rgb(6, 211, 143);
}
#apfirst{
    grid-area: apfirst;
    background-color: beige;
}
#aplast{
    grid-area: aplast;
    background-color: beige;
}
#apmail{
    grid-area: apmail;
    background-color: beige;
}
#apmes{
    grid-area: apmes;
    background-color: beige;
}
#apbtn{
    grid-area: apbtn;
    background-color: rgb(80, 189, 223);
    padding-top: 4px;
}
#apspace{
    grid-area: apspace;
    background-color: beige;
}
#apfoot{
    grid-area: apfoot;
    background-color: chartreuse;
}

@media only screen and (max-width: 550px){
    #prayercontainer-fluid{
        grid-template-columns: 1fr;
        grid-template-rows: 50px 50px 75px 75px 75px 170px 50px 40px 50px;
        grid-template-areas: "apnav"
                             "aptitle"
                             "apfirst"
                             "aplast"
                             "apmail"
                             "apmes"
                             "apbtn"
                             "apspace"
                             "apfoot";
    }
}
#fncolor,#lncolor{
    background-color: transparent;
    text-align: center;
    font-size: 20px;
    height: 50px;
    width:50%;
    border: none;
}
#emcolor{
    background-color: transparent;
    text-align: center;
    font-size: 20px;
    height: 50px;
    width: 50%;
    border: none;
}
#apmes{
    font-size: 15px;
    border: 0;
}
/* #apspace{
    padding-top: 60px;
} */
#apsubmit{
    background-color: blue;
    color: white;
    height: 40px;
    width: 100px;
    font-size: 20px;
}