#dcontainer-fluid{
    display: grid;
    height: auto;
    text-align: center;
    grid-template-columns: 50% 50%;
    grid-template-rows: 30px 30px 50px 30px 200px 30px 200px 55px;
    grid-template-areas: "dnav dnav"
                         "dtitle1 dtitle1"
                         "dtitle2 dtitle2"
                         "dftitle dctitle"
                         "dfrank dclinton"
                         "dbtitle dbutitle"
                         "dbarkey dbutler"
                         "dfooter dfooter";
    grid-gap: 0.3rem;
}
#dnav{
    grid-area: dnav;
    background-color: beige;
}
#dtitle1{
    grid-area: dtitle1;
    background-color: aqua;
}
#dtitle2{
    grid-area: dtitle2;
    background-color: aquamarine;
}
#dftitle{
    grid-area: dftitle;
    background-color: beige;
}
#dctitle{
    grid-area: dctitle;
    background-color: beige;
}
#dfrank{
    grid-area: dfrank;
    background-color: cadetblue;
}
#dclinton{
    grid-area: dclinton;
    background-color: cadetblue;
}
#dbtitle{
    grid-area: dbtitle;
    background-color: beige;
}
#dbutitle{
    grid-area: dbutitle;
    background-color: beige;
}
#dbarkey{
    grid-area: dbarkey;
    background-color: cadetblue;
}
#dbutler{
    grid-area: dbutler;
    background-color: cadetblue;
}
#dfooter{
    text-align: center;
    grid-area: dfooter;
    background-color: chartreuse;
}

@media only screen and (max-width: 815px){
    #dcontainer-fluid{
        grid-template-columns: auto;
        grid-template-rows: 30px 30px 50px 30px 200px 30px 200px 40px ;
        grid-template-areas: "dnav"
                             "dtitle1"
                             "dtitle2"
                             "dftitle"
                             "dfrank"
                             "dctitle"
                             "dclinton"
                             "dbtitle"
                             "dbarkey"
                             "dbutitle"
                             "dbutler"
                             "dfooter";
    }
}

#mapimgf,#mapimgc,#mapimgb,#mapimgb2{
    height: 200px;
}
#dh1{
    font-size: 40px;
}