#fcontainer{
    display: grid;
    height: auto;
    text-align: center;
    grid-template-columns: 100%;
    grid-template-rows: 30px 30px 450px 50px;
    grid-template-areas: "fnav"
                         "ftitle"
                         "fimg"
                         "ffooter";

    grid-gap: 0.3rem;
}
#fnav{
    grid-area: fnav;
    background-color: aqua;
}
#ftitle{
    grid-area: ftitle;
    background-color: bisque;
    text-align: center;
}
#fimg{
    text-align: center;
    grid-area: fimg;
    background-color: rgb(47, 177, 177);
}
#ffooter{
    grid-area: ffooter;
    background-color: chartreuse;
}

@media only screen and(max-width: 550px){
    #fcontainer-fluid{
        grid-template-columns: 100%;
        grid-template-rows: 30px 30px 250px 50px;
        grid-template-areas: "fnav"
                             "ftitle"
                             "fimg"
                             "ffooter";
    }
}


#bigfimg{
    max-width: 100%;
    max-height: 450px;
}
#fh3{
    font-size: 30px;
}