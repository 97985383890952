#plthn{
    width: 50px;
    max-width: 75px;  
  }
  
  #plthfn,#pltdfn{
    width: 150px;
    max-width: 175px;
   }
   #plthln,#pltdln{
   width: 150px;
   max-width: 175px;
   }
   #plthcmt,#pltdcmt{
     width: 400px;
     max-width: 450px;
   }
   #plthca,#pltdca{
   width: 40px;
   max-width: 60px;
   }