.ccontainer {
  display: grid;
  grid-template-rows: 50px 50px 100px;
  grid-template-columns:repeat(5, 1fr);
  grid-gap: 2px;
  background-color: sandybrown;
  padding: 10px;
  color: black;
  font-size: 15px;
  font-weight: 500;
  border-bottom: black 2px dashed;
}
.ch1div{
  display: flex;
  justify-content: center;
}
.cch1{
  font-size: 40px;
  font-style: italic;
  text-decoration: underline;
}
.ch1{
  font-size: 50px;
  font-family: 'Libre Baskerville', serif;
  font-weight: 500;
  text-decoration: underline;
}

.ch1{
  color: blue;
  font-size: 20px;
}
.btndiv{
  height: 65px;
}
.cbtn{
  background-color: blue;
  color: white;
  font-weight: 500;
}
