#ccontainer-fluid{
    display: grid;
    height: auto;
    text-align: center;
    grid-template-columns: 100%;
    grid-template-rows: 30px 30px 450px 50px;
    grid-template-areas: "cnav"
                         "ctitle"
                         "cimg"
                         "cfooter" ;
    grid-gap: 0.3rem;
}

#cnav{
    grid-area: cnav;
    background-color: aqua;
}
#ctitle{
    grid-area: ctitle;
    background-color: bisque;
}
#cimg{
    text-align: center;
    grid-area: cimg;
    background-color: cadetblue;
}
#cfooter{
    text-align: center;
    grid-area: cfooter;
    background-color: chartreuse;
}

@media only screen and(max-width: 550px){
    #ccontainer-fluid{
        grid-template-columns: 100%;
        grid-template-rows: 30px 30px 250px 50px;
        grid-template-areas: "cnav"
                             "ctitle"
                             "cimg"
                             "cfooter";
    }
}



#bigcimg{
    max-width: 100%;
    max-height: 450px;
}

