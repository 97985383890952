#cucontainer-fluid{
    background-color: aquamarine;
    display: grid;
    height: auto;
    text-align: center;
    grid-template-columns: 50% 50%;
    grid-template-rows: 30px 60px 90px 325px 60px;
    grid-template-areas: "cunav cunav"
                         "cutitle cuctitle" 
                         "cuaddress cuform"
                         "cuinfo cuform"
                         "cufooter cufooter";
     grid-gap: 0.3rem;                                     
}
#cunav{
    grid-area: cunav;
    background-color: aquamarine;
}
#cutitle{
    padding: 9px 0 0 0;
    grid-area: cutitle;
    background-color: rgb(140, 127, 255);
}
#cuctitle{
    padding: 9px 0 0 0;
    grid-area: cuctitle;
    background-color: rgb(140, 127, 255);
}
#cuaddress{
    grid-area: cuaddress;
    background-color: rgb(83, 83, 128);
}
#cuinfo{
    grid-area: cuinfo;
    background-color: rgb(53, 53, 219);

}
#cuform{
    padding: 20px 0 0 0;
    grid-area: cuform;
    background-color:aquamarine;
}
#cufooter{
    padding: 5px 0 0 0;
    grid-area: cufooter;
    background-color: chartreuse;
}
 
@media only screen and (max-width: 550px){
    #cucontainer-fluid{
    grid-template-columns: 1fr;
    grid-template-rows: 30px 60px 70px 280px 60px 250px 60px;
    grid-template-areas: "cunav"
                         "cutitle"
                         "cuaddress"
                         "cuinfo"
                         "cuctitle"
                         "cuform"
                         'cufooter';

    }
}
#cuh5{
    color: white;
    font-size: 16px;
    padding: 30px 0 0 0;
}
#cuh4{
    color: white;
    font-size: 20px;
    padding: 5px 0 0 0;
}