.bpsect{
    border-bottom: 5px solid rgb(226, 7, 7);
    height: 45px;
}
.bptitle{
    background-color: rgb(226, 157, 73);
    text-align: center;
   
}
.contdiv{
    background-color: bisque;
    height: 600px;
    padding-top: 20px;
    text-align: center;
}
.bph2{
    margin: 0;
}
.dlrow2col1{
    text-align: center;
}
