#barcontainer-fluid{
    display: grid;
    height: auto;
    text-align: center;
    grid-template-columns: 100%;
    grid-template-rows: 30px 30px 450px 50px;
    grid-template-areas: "barnav"
                         "bartitle"
                         "barimg"
                         "barfooter" ;
    grid-gap: 0.3rem;
}

#barnav{
    grid-area: barnav;
    background-color: aqua;
}
#bartitle{
    grid-area: bartitle;
    background-color: bisque;
}
#barimg{
    text-align: center;
    grid-area: barimg;
    background-color: cadetblue;
}
#barfooter{
    text-align: center;
    grid-area: barfooter;
    background-color: chartreuse;
}

@media only screen and(max-width: 550px){
    #barcontainer-fluid{
        grid-template-columns: 100%;
        grid-template-rows: 30px 30px 250px 50px;
        grid-template-areas: "barnav"
                             "bartitle"
                             "barimg"
                             "barfooter";
    }
}



#bigbimg{
    max-width: 100%;
    max-height: 450px;
}

