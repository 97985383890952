@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
.evsect {
  background-image: url(../images/wp3308780.jpg);
}
.rbc-toolbar-label{
  font-size: 35px;
  font-weight: 500;
  color: yellow;
  text-shadow: 3px 2px 3px black;
  font-family: 'Abril Fatface', serif;
}
.rbc-agenda-date-cell,.rbc-agenda-time-cell,.rbc-agenda-event-cell{
  font-size: 19px;
  font-weight: 600;
  color: black;
  text-shadow: 2px 1px 1px beige;
}
/* .evh1 {
  display: flex;
  justify-content: center;
  font-family: 'Vina Sans', sans-serif;
  font-size: 45px;
} */
.evh2 {
  display: flex;
  justify-content: center;
  font-family: "Oswald", sans-serif;
  font-size: 25px;
}
.react-datepicker__input-container {
  display: flex;
  justify-content: center;
  margin: 5px;
}
.evdiv,
.evbtn {
  display: flex;
  justify-content: center;
  margin: 5px 0 5px 0;
}
.evdiv {
  padding-left: 11px;
}