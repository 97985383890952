#bucontainer-fluid{
    display: grid;
    height: auto;
    text-align: center;
    grid-template-columns: 100%;
    grid-template-rows: 30px 30px 450px 50px;
    grid-template-areas: "bunav"
                         "butitle"
                         "buimg"
                         "bufooter" ;
    grid-gap: 0.3rem;
}

#bunav{
    grid-area: bunav;
    background-color: aqua;
}
#butitle{
    grid-area: butitle;
    background-color: bisque;
}
#buimg{
    grid-area: buimg;
    background-color: cadetblue;
    text-align: center;
}
#bufooter{
    text-align: center;
    grid-area: bufooter;
    background-color: chartreuse;
}

@media only screen and(max-width: 550px){
    #bucontainer-fluid{
        grid-template-columns: 100%;
        grid-template-rows: 30px 30px 250px 50px;
        grid-template-areas: "bunav"
                             "butitle"
                             "buimg"
                             "bufooter";
    }
}



#buimg{
    max-width: 100%;
    max-height: 450px;
}








