.mpsect1{
    background-image: url(../images/sunset.jpg);
    max-height: 238px;
    width: 100%;
  }
  .mplogo{
   max-height: 250px;
   max-width: 350px;
  }
.versepar{
  color: black;
  font-size: 12px;
  font-weight: 600;
  margin: 0 0 5px 0;
}
.pardiv{
  width: 350px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 15px;
}
.fbdiv{
  padding: 0 0 5px 0;
  width: fit-content;
}
.mph4{
  color:#43609C;
  font-weight: 600;
  font-size: 20px;
  text-shadow: 2px 1px 2px burlywood;
  margin: 0;
}
.mph4:hover{
  color: beige;
  text-shadow: 2px 1px 2px black;
  font-size: 23px;
}