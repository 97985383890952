@import url("https://fonts.googleapis.com/css2?family=Sansita+Swashed:wght@500&display=swap");

#aucontainer-fluid{
    background-image: url(../images/311116-beige-background-1920x1080-iphone.jpg);
    display: grid;
    justify-content: center;
    height: max-content;
    grid-template-columns: 1fr;
    grid-template-rows: 30px 50px auto 60px;
    grid-template-areas: "nav"
                         "title"
                         "aumain"
                         "footer";
}
#aunav{
    background: lightblue;
    grid-area: nav;
    height:auto;
    display: flex;
    justify-content: left;
    border: black 1px solid;
}
#autitle{
    grid-area: title;
    /* background-color: burlywood; */
    color: black;
    text-align: center;
}
.auh1{
    color: black;
    font-size: 35px;
    font-family: "Sansita Swashed", cursive;
    text-shadow: 2px 3px 1px rgb(229, 229, 150);
}
#aumain{
    grid-area: aumain;
        display: flex;
        padding: 15px 0 15px 0;
        justify-content: center;
        text-align: center;
       
    
    }
#aufooter{
        background: blue;
        grid-area: footer;
    }
    .aupar {
        font-size: 20px;
        font-weight: 600;
        background-color: rgba(241, 239, 238, 0.4);
        width: 60%;
        align-content: center;
        border: black 2px solid;
        border-style: outset;
        border-radius: 20px;
        padding: 0 25px 0 15px;
        color: black;
      }    

      @media only screen and (max-width: 550px){
        .aupar{
            width: 90%;
        }
      }