.logo-div {
  display: flex;
  justify-content: center;
}
.copy-p {
  justify-content: center;
  color: #073b3a;
  background-color: aliceblue;
  padding: 0 10px 0 10px;
  font-weight: 600;
  border: black 1px solid;
  border-radius: 8px;
}
.copy-pr {
  display: flex;
  justify-content: center;
}
.edssml{
  width: 80px;
  height: 45px;
}
