

/* default styling */

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: yellow;
    text-shadow: 2px 1px 2px black;
    font-weight: 600;
    font-size: 15px;
  }

  /* navbar */
  .navbar {
    background-color: rgb(99, 189, 192);
    /* padding: 10px 20px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    min-height: 30px;
  }


  /* logo  */
  /* .logo {
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
  } */


  /* ul  */
  .navMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  /* media queries */

  @media screen and (max-width: 600px){

    /* hamburger */
    .hamburger {
      display: block;
      cursor: pointer;
    }

    .bar {
          background-color: #120f0b;
          width: 20px;
          height: 3px;
          display: block;
          margin: 3px;
          -webkit-transition: 0.3s ease-in-out;
          transition: 0.3s ease-in-out;
      }

    /* adding active class to the bars  */
    .hamburger.active .bar:nth-child(2){
      opacity: 0;
    }
    .hamburger.active .bar:nth-child(1){
      transform: translateY(8px) rotate(45deg);
    }
    .hamburger.active .bar:nth-child(3){
      transform: translateY(-8px) rotate(-45deg);
    }


    /* navMenu */

    .navMenu{
      position: absolute;
      flex-direction: column;
      gap: 0;
      top: 50px;
      left: -100%;
      text-align: start;
      width: 40%;
      transition: 0.7s ease-in-out;
      background-color:bisque;
      border-radius: 15px;
      padding: 10px;
    }

    .navMenu.active{
      left: 0;
    }

    .navMenu li {
      margin: 16px 0;
    }
  }
a:hover{
  color: beige;
  font-size: 18px;
}
