
.container-fluid{
    display: grid;
    height: auto;
    text-align: center;
    grid-template-columns: 50% 50%;
    grid-template-rows: 30px 220px 40px 250px 55px;
    grid-template-areas: "nav nav"
                         "main main"
                         "mmarquee mmarquee"
                         "content1 content3"
                         "footer footer";
    grid-gap: 0.2rem;                   
}
nav{
    background: aquamarine;
    grid-area: nav;
    height:auto;
    display: flex;
    justify-content: left;
}
#mh1{
    font-size: 35px;
}
#m10am{
    color: red;
}
#mmarquee{
    width: 100%;
    grid-area: mmarquee;
    background-color: beige;
}
main{
background: #84ffff;
grid-area: main;

}
#content1{
    background: beige;
    grid-area: content1;

}
#content2{
    background: beige;
    grid-area: content2;
    height: 250px;
    width: 420px;
}
#content3{
    background: beige;
    grid-area: content3;
}
footer{
    background: #1de9b6;
    grid-area: footer;
}

@media only screen and (max-width: 550px){
    .container-fluid{
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 35px 2fr 2fr 2fr 0.8fr;
    grid-template-areas: "nav"
                         "main"
                         "mmarquee"
                         "content1" 
                         "content2"
                         "content3"
                         "footer" ;
    }
}
.vid{
    height: 250px;
    width: 420px;
}
#img{
    /* padding: 5px 0 0 0; */
    width: 420px;
    height: 240px;
}